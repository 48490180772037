//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import TakeoutQuery from "../../components/qshop/takeoutQuery";
import MealQuery from "../../components/qshop/mealQuery";
import PopupFullPicker from "../../components/qshop/popup-full-picker";
import selectInfo from "../../components/qshop/selectInfo.js";
import closeWebview from "../../components/qshop/closeWebview";
export default {
  mixins: [selectInfo, closeWebview],
  data() {
    return {
      selected: "1",
      showPicker: false
    };
  },
  computed: {
    ...mapGetters(["tab", "TakeoutCityDictVal", "hidddenQuery"])
  },
  created() {
    let selected = this.iStorage.get("mealQueryType") || "1";
    this.selected = selected;
    this.iStorage.remove("mealQueryType");
  },
  methods: {
    closeqShop() {
      this.closeWebview();
    }
  },
  components: {
    TakeoutQuery,
    MealQuery,
    PopupFullPicker
  }
};
