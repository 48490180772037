//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import { mapGetters, mapActions } from 'vuex'
	// import PopupFullPicker from '@/components/popup-full-picker'
	import selectInfo from './selectInfo.js'
	import { mealQuery as mealQueryValidator } from '../validatorConfig/mealQuery'
	export default {
		extends: selectInfo,
		data() {
			return {

			}
		},
		computed: {
			...mapGetters([
				'MealCityDictVal',
				'mettingType'
			])
		},
		methods: {
			...mapActions([
				'GetOpenMealUrlPromise'
			]),
			selectCity() {
				const currentRoute = this.$route.path
				this.$router.push({
					path: '/selectCity',
					query: {
						mealQueryType: 2,
						backRoute: currentRoute
					}
				})
			},
			urlQuy(url) {
				let query = url.split('?')
				let queryObj = {}
				query[1].split('&').forEach(item => {
					let itemArr = item.split("=")
					queryObj[itemArr[0]] = itemArr[1]
				})
				return queryObj
			},
			async search() {
				var params = {
					cityDictVal: this.MealCityDictVal,
					// typeMeetVal: this.mettingType.val
					tenantId: this.iStorage.get('tenantId')
				}
				const validateResult = this.validate(params, mealQueryValidator);
				if(!validateResult) return;
				const res = await this.GetOpenMealUrlPromise(params)
				if(res && res.success) {
					let mshopUrl = res.content;
					const rnd = new Date().getTime();
					mshopUrl += "&action=search&qshop=true&rejecttabs=0,2" + "&rnd=" + rnd;
					let urlObj = this.urlQuy(mshopUrl)
					let appType = this.iStorage.get('appType');
					if(appType == 'iEvent') {
						urlObj.appfrom = 'ievent'
					}
					this.$router.push({
						path: "/index",
						query: {
							...urlObj
						}
					});
					this.iStorage.set('Selected', "2")
				}
			}
		},
		// components: {
		//     PopupFullPicker
		// }
	}
